@font-face {
  font-family: 'thesansplain';
  src: url('/assets/fonts/thesansplain/thesansplain-webfont.woff2') format('woff2'),
  url('/assets/fonts/thesansplain/thesansplain-webfont.woff') format('woff'),
  url('/assets/fonts/thesansplain/thesansplain.ttf')  format('truetype')
}

@import "scss/bootstrap-variables";
@import "scss/dotstyle";
@import "~bootstrap/scss/bootstrap";
@import "~@ng-select/ng-select/themes/default.theme.css";

@import "scss/backgrounds";
@import "scss/layout";
@import "scss/colors";
@import "scss/poi";
@import "scss/custom";



.info-white {
  background-image: url("/assets/img/svg/tooltip_icon_white_17x17.svg");
  width: 17px;
  height: 17px;
  background-repeat: no-repeat;
  display: inline-block;
  border: 0;
  background-color: transparent;
  margin: 0 0 0 8px;
}

.info-grey {
  background-image: url("/assets/img/svg/tooltip_icon_grey_17x17.svg");
  width: 17px;
  height: 17px;
  background-repeat: no-repeat;
  display: inline-block;
  border: 0;
  background-color: transparent;
  margin: 0 0 0 8px;
}

.btn-arrow-right {
  display: inline-block;
  position: relative;
  &:after {
    left: 99%;
    top: 50%;
    border: solid transparent;
    content: '';
    margin-top: -15px;
    width: 0;
    height: 0;
    position: absolute;
    pointer-events: none;
    border-width: 15px;
    border-color: transparent;
    border-left-color: #1ca460;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
}

.mapboxgl-popup-content {
  text-align: left;
}

[data-order=desc],
[data-order=asc] {
  position: relative;
}
[data-order=asc]:after {
  content: "";
  margin-left: 0.25rem;
  display: inline-block;
  position: absolute;
  right: 2px;
  bottom: 16px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid black;
}

[data-order=desc]:after {
  content: "";
  margin-left: 0.25rem;
  display: inline-block;
  position: absolute;
  right: 2px;
  bottom: 16px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid black;
}

.maplibregl-popup-close-button {
  display: none;
}

@media (max-width: 991px) {
  .navbar-brand img {
    width: 180px
  }

  .nav-link {
    color: #000000;
    &:hover {
      color: #555555;
    }
  }

  body {
    margin-top: 82px;
  }

  app-header{
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    height: 82px;
    z-index: 4999;
  }
}

.navbar-toggler:focus {
  box-shadow: none;
}









