body{
  color: #000;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility !important;
}


#footer .bg-credits {
  transform: translate(1192px, 59px);
}

#footer .credits_md {
  transform: translate(-94px, 5px);
}

#footer {
  background-color: #f3f3f3;
  padding-bottom: 50px;
}

footer .row{
  position: relative;
}
.link-light {
  text-decoration: none;
}

footer a:hover, footer a:visited{
  color:#fff !important;
}

.py-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.navbar-brand {
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap;
}


.ms-10 {
  margin-left: 118px
}

.container-narrow .col {
  position: relative;
}

