.marker-CAS {
  background-image: url('/assets/poi/cas.png');
  width: 18px;
  height: 31px;
  cursor: pointer;
}

.marker-SAI {
  background-image: url('/assets/poi/sai.png');
  width: 18px;
  height: 31px;
  cursor: pointer;
}

.marker-PRIMA {
  background-image: url('/assets/poi/prima_acc.png');
  width: 18px;
  height: 31px;
  cursor: pointer;
}

.outside-selected-territory {
  opacity: .3;
  &:hover {
    opacity: 1;
  }
}
