/* Common styles and resets */

.dotstyle ul {
  position: fixed;
  z-index: 1000;
  top: 40%;
  right: 15px;
  display: inline-block;
  font-size: 14px;
  margin: 0;
  padding: 0;
  list-style: none;
  cursor: default;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.dotstyle li {
  position: relative;
  display: block;
  margin: 16px;
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.dotstyle li a {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  outline: none;
  text-decoration: none;
  border-radius: 50%;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.3);
  text-indent: -999em; /* make the text accessible to screen readers */
  cursor: pointer;
  position: absolute;
}

.dotstyle li a:focus {
  outline: none;
}

/* Tooltip */

.dotstyle-tooltip li {
  border: 2px solid #a4a4a4;
  border-radius: 50%;
  z-index: 1000;
  -webkit-transition: border-color 0.3s ease;
  transition: border-color 0.3s ease;
}

.dotstyle-tooltip li a {
  top: auto;
  bottom: 60%;
  left: -16px;
  visibility: hidden;
  padding: 0;
  width: auto;
  height: auto;
  border-radius: 0;
  text-indent: 0;
  line-height: 2;
  opacity: 0;
  -webkit-transform: translateX(-75%) translateY(50%);
  transform: translateX(-75%) translateY(50%);
  -webkit-transition: -webkit-transform 0.3s ease, opacity 0.3s ease, visibility 0s 0.3s ease;
  transition: transform 0.3s ease, opacity 0.3s ease, visibility 0s 0.3s ease;
  white-space: nowrap;

  span {
    font-family: sans-serif;
    background-color: #1ca460;
    color: #fff;
    display: inline-block;
    font-size: 18px;
    line-height: 30px;
    vertical-align:middle;
    padding: 4px 12px 5px 12px;
    margin-left: 5px;
  }
}

.dotstyle-tooltip li a::after { /* http: //cssarrowplease.com/ */
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: '';
  margin-top: -10px;
  width: 0;
  height: 0;
  position: absolute;
  pointer-events: none;
  border-width: 10px;
  border-color: transparent;
  border-left-color: #1ca460;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}

.dotstyle-tooltip li:hover a,
.dotstyle-tooltip li.current a {
  z-index: 100;
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateX(-100%) translateY(50%);
  transform: translateX(-100%) translateY(50%);
  -webkit-transition: -webkit-transform 0.3s ease, opacity 0.3s ease;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.dotstyle-tooltip li:hover {
  border-color: #1ca460;
}

.dotstyle-tooltip li.current {
  border-color: #1ca460;
  background-color: #1ca460;
}

.dotstyle-tooltip li:hover {
  z-index: 100;
}

.no-touch .dotstyle-tooltip ul:hover li.current a {
  opacity: 0.2;
}

.dotstyle-tooltip ul li.current:hover a {
  opacity: 1;
}
