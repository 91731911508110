$font-family-base: 'thesansplain', sans-serif;
$body-bg: #ffffff;
$link-color: #1ca460;
$link-decoration: none;

$enable-rounded: true;
$enable-shadows: true;

$font-size-base:	1rem;
$headings-font-weight: 400;
$h1-font-size: $font-size-base * 4;
$h2-font-size: $font-size-base * 2.7;
$h3-font-size: $font-size-base * 2;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base * 1.125;

$carousel-control-color: $body-bg;
$carousel-indicator-active-bg: #999;
$carousel-indicator-width:	10px;
$carousel-indicator-height:	10px;

$hr-margin-y: 5px;

$nav-link-color: #fff;
$nav-link-hover-color: #dedede;

$navbar-light-toggler-border-color:	rgba(#000, 0);

$dropdown-link-active-color: #ffffff;
$dropdown-link-active-bg: #333333;


$pagination-border-color: transparent;
$pagination-bg: transparent;
$pagination-active-color: #ffffff;
$pagination-active-bg: #333333;
$pagination-disabled-color: #acacac;
$pagination-disabled-bg: transparent;
$pagination-disabled-border-color: transparent;

$nav-tabs-border-color:             #cecece;
// $nav-tabs-border-width:             $border-width !default;
$nav-tabs-border-radius:            0;
// $nav-tabs-link-hover-border-color:  $gray-200 $gray-200 $nav-tabs-border-color !default;
// $nav-tabs-link-active-color:        $gray-700 !default;
$nav-tabs-link-active-bg:           #f4f4f4;
// $nav-tabs-link-active-border-color: $gray-300 $gray-300 $nav-tabs-link-active-bg !default;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1520px
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1470px
) !default;
