.bg-green-gradient {
  background: rgb(0,34,59);
  background: linear-gradient(90deg, rgba(0,34,59,1) 0%, rgba(28,164,96,1) 100%);
}

.bg-lightgrey {
  background-color: #f3f3f3;
}

.bg-lancio {
  background: #f3f3f3 url("/assets/img/bg_lancio.png") no-repeat center left;
  background-size: cover;
  width: 100%;
  background-position: center;
  position: relative;
  z-index: 100;
}

.bg-report {
  background: url("/assets/img/bg_report.png") no-repeat center left;
  background-size: cover;
}

.bg-credits {
  border: none;
  background-image: url("/assets/img/svg/bg_credits_1635x53.svg");
  width: 1635px;
  height: 53px;
  background-repeat: no-repeat;
  position: absolute;
  transform: translate(-305px, -12px);
}

.gradient-territori {
  background: rgb(210,235,231);
  background: linear-gradient(90deg, rgb(164, 208, 188) 0%, rgb(48, 104, 78) 100%);
}

.bg-scale-0-100 {
  width: 180px;
  height: 20px;
  margin: 20px 10px 10px 10px;
  padding-top: 8px;
  font-size: 14px;
  color: #666;
  background-size: 180px 10px;
  background-position: top;
  background-repeat: no-repeat;
}

@media (max-width: 768px) {
  .bg-scale-0-100 {
    width: 100px;
  }
}
