html,
body {
  overflow-x: hidden; /* Prevent scroll on narrow devices */
}

app-home {
  display: block;
  min-height: 400px;
}
@media (max-width: 991.98px) {
  .offcanvas-collapse {
    position: fixed;
    z-index: 5000;
    top: 82px; /* Height of navbar */
    bottom: 0;
    left: 100%;
    width: 100%;
    height: calc(100vh - 150px);
    padding-right: 1rem;
    padding-left: 1rem;
    overflow-y: auto;
    visibility: hidden;
    background-color: #fafafa;
    transition: transform .3s ease-in-out, visibility .3s ease-in-out;
  }
  .offcanvas-collapse.open {
    visibility: visible;
    transform: translateX(-100%);
  }
}



.section-title {
  color: #ffffff;
  position: relative;
  span:first-child {
    display: inline-block;
    background-color: #00223b;
    padding: 4px 12px 5px 12px;
    line-height: 30px;
    vertical-align:middle;
  }
  span:last-child {
    font-family: sans-serif;
    display: inline-block;
    background-color: #1ca460;
    font-size: 20px;
    line-height: 30px;
    vertical-align:middle;
    padding: 4px 12px 5px 12px;
  }
}
@media (max-width: 400px) {
  .section-title {
    font-size: 0.9rem;

    span:last-child {
      font-size: 16px;
    }
  }

}



.container-narrow {
  max-width: 996px;
  margin-left: auto;
  margin-right: auto;
}

.container-mini {
  max-width: 520px;
  margin-left: auto;
  margin-right: auto;
}

.no-tab-padding {
  .nav-link { background-color: #ffffff; height: 100%; &.active { height: auto; }}
}

table.table {
  tbody, thead {
    tr {
      th, td {
        border-left: 1px solid #c1c1c1;
        border-right: 1px solid #c1c1c1;
        font-weight: normal;
        &:first-child {
          border-left: none;
          text-align: left;
        }
        &:last-child {
          border-right: none;
        }
      }
    }
  }
}

.scrollable-box {
  .dropdown-item {
    padding: 0;
  }
  .ng-dropdown-panel {
    position: static;
  }
}

.fs-14 {
  font-size: 0.875rem;
}

.ms-118 {
  margin-left: 118px;
}

.opacity-95 { opacity: .95 !important; }

app-panel-numeri {
  min-height: 542px;
}

.pagination {
  display: flex;
  justify-content: center;
}
